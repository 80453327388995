.top-nav {
  display: flex;
  justify-content: space-between;
  background: $primary-color;

  nav {
    padding: 0 20px;
    @include medium-up {
      padding: 0 25px;
    }

    ul {
      display: flex;
      list-style: none;
      padding: 0;

      li {
        padding-right: 20px;
        @include medium-up {
          padding-right: 25px;
        }

        a {
          color: $top-nav-link-color;
          text-decoration: none;
          text-transform: uppercase;
          font-size: 12px;
          letter-spacing: 1px;
          font-weight: $regular;

          &:hover,
          &:focus {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .search-box {
    background: $primary-darker-color;
    align-items: center;
    display: none;
    position: absolute;
    width: 100%;
    padding: 15px 20px;
    top: 50px;
    z-index: 1;
    @include medium-up {
      padding: 0 25px;
      display: flex;
      position: relative;
      width: auto;
      top: 0;
    }

    &.active {
      display: block;

      .search-container {
        width: 100%;
        display: flex;

        input[type="text"] {
          width: 100%;
        }
      }
    }

    .search-container {
      input[type="text"] {
        background: transparent;
        border: none;
        color: white;

        &::placeholder {
          color: white;
        }

        &:focus {
          outline: 1px solid black;
        }
      }

      .btn-search {
        color: white;

        &:focus {
          color: $tan;
        }
      }
    }
  }

  .search-toggle {
    padding-right: 20px;
    display: block;
    @include medium-up {
      display: none;
    }

    &:hover,
    &:focus {
      .fa-search,
      .fa-times {
        color: white;
        background: $primary-darker-color;
      }
    }

    .fa-search {
      border-radius: 50px;
      padding: 10px;
      background: $primary-darker-color;
      color: white !important;
    }

    .fa-times {
      border-top-right-radius: 50px;
      border-top-left-radius: 50px;
      padding: 10px 12px 18px;
      background: $search-close-background-color;
    }

    .fa-search,
    .fa-times {
      font-size: 14px;
      color: white;
      position: relative;
      top: 8px;
      display: none;
      cursor: pointer;

      &.active {
        display: block;
        color: white;
      }
      @include medium-up {
        display: none;
      }

      &:hover,
      &:focus {
        color: white !important;
      }
    }
  }
}