a {
  &.btn {
    display: inline-block;
    padding: 15px 35px;
    margin-left: 0;
    border-radius: 50px;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;

    &.btn-primary {
      background: $primary-color;
      border: 1px solid $primary-color;
      color: white;

      &:hover,
      &:focus {
        background: $tan;
        border-color: $tan;
        color: $button-text;
      }
    }

    &.btn-secondary {
      background: $tan;
      border: 1px solid $tan;
      color: $button-text;

      &:hover,
      &:focus {
        background: $primary-color;
        border: 1px solid $primary-color;
        color: white;
      }
    }
  }
}