body.authenticated {
  .alerts {
    display: inline-flex !important;
    align-items: center;
    text-align: left;
    width: 100%;
  }
}

.alerts {
  // display: none;
  background: #8dc7e9 !important;

  .alert {
    color: $primary-darker-color !important;
    text-decoration: none;
    background: #8dc7e9 !important;

    &:hover,
    &:focus {
      text-decoration: none !important;

      .alert-text {
        text-decoration: underline !important;
      }
    }

    @media (min-width: 640px) {
      padding: 16px 60px !important;
      font-size: 18px;
    }

    &:before {
      content: 'Alert!';
      display: block;
      font-family: $primary-font;
      font-weight: 700;
      font-size: 16px;
      text-transform: uppercase;
      margin-right: 0;

      @media (min-width: 640px) {
        display: inline-block;
        margin-right: 10px;
        font-size: 18px;
      }
    }

    .icon {
      display: none;
    }

    .alert-text {
      text-align: left;
    }
  }

  .close {
    position: absolute;
    color: $primary-darker-color !important;
    top: 20px;
    right: 30px;
    cursor: pointer;
  }
}