#speedbump {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1000;
  display: none;
  padding: 20px;
  width: 70%;
  border: 1px solid #efefef;
  background: #ffffff;
  transform: translateX(-50%) translateY(-50%);
  box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.5);
  border: 2px solid #9e9e9e;
  width: 60%;

  p {
    color: #000;
  }

  @media (max-width: 768px) {
    width: 75%;
  }

  &.open {
    display: block;
  }

  h3 {
    @media (max-width: 768px) {
      margin-top: 20px;
    }
  }

  .close-reveal-modal {
    position: absolute;
    top: 0;
    right: 20px;
    border: 0;
    color: red;
    text-decoration: none !important;
    font-weight: bold;
    cursor: pointer;
    font-size: 30px;
  }

  .btn {
    display: inline-block;
    margin: 20px 0;
    padding: 0 15px;
    width: auto;
    color: #ffffff;

    @media (max-width: 768px) {
      max-width: 50%;
    }

    &:visited {
      color: #ffffff;
    }
  }
}