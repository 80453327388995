.two-column,
.three-column,
.four-column,
.five-column,
.six-column {
    @include medium-up {
        margin: 0;
    }
}

.two-column .col.two-thirds {
    @include medium-up {
        width: 67%;
    }
}

.two-column .col.one-third {
    @include medium-up {
        width: 33%;
    }
}

.col-sm-2 {
    width: 20%;

    @media (max-width: 1100px) and (min-width: 550px) {
        width: 45%;
    }

    @media (max-width: 550px) {
        width: 100%;
    }
}

.col-sm-3 {
    width: 25%;

    @media (max-width: 1100px) and (min-width: 550px) {
        width: 45%;
    }

    @media (max-width: 550px) {
        width: 100%;
    }
}

.col-sm-4 {
    width: 33%;

    @media (max-width: 1100px) and (min-width: 550px) {
        width: 45%;
    }

    @media (max-width: 550px) {
        width: 100%;
    }
}

.col-sm-5 {
    width: 40%;

    @media (max-width: 1100px) and (min-width: 550px) {
        width: 45%;
    }

    @media (max-width: 550px) {
        width: 100%;
    }
}

.col-sm-6 {
    width: 45%;

    @media (max-width: 550px) {
        width: 100%;
    }

}

.col-sm-12 {
    width: 80%;

    @media (max-width: 550px) {
        width: 100%;
    }
}