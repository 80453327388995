.branding {
  position: relative;
  display: block;
  width: 100%;
  padding: 20px;
  background: $header-background-color;
  @include medium-up {
    position: relative;
    top: 0;
    width: 140px;
    // height: 119.5px;
    padding: 0;
  }

  a {
    transform: none;
    // width: 140px;
    // height: 119.5px;
    position: relative;
    margin: auto;
    @include medium-up {
      // position: absolute;
      // top: 50%;
      z-index: 1;
      display: inline-block;
      // transform: translateY(-50%);
    }

    .logo {
      background-position: center;
      background: url(/themes/athena/assets/dist/images/logo.png) no-repeat;
      background-size: contain;
      width: 140px;
      height: 119.5px;
      display: block;
      @include medium-up {
        display: inline-block;
      }
    }
  }
}