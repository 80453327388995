.cta-banner {
  background: $primary-color;

  a {
    color: white;

    &:hover,
    &:focus {
      color: black;
    }
  }

  .btn.btn-primary {
    background-color: $tan;

    &:hover,
    &:focus {
      background: white;
      color: $primary-color;
      border-color: white;
    }
  }
}

.cta-billboard {
  height: auto;
  min-height: auto;
  margin-top: 35px;
  margin-bottom: 40px;

  .img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    max-width: 100%;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .content {
    position: relative;
    padding: 15px 0;
    @include medium-up {
      padding: 65px 0;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      @include fontsize(24 24);
      margin-top: 20px;
      color: white;
    }

    p {
      color: white;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  a.btn,
  a.btn-primary {
    color: $primary-color;

    &:hover,
    &:focus {
      color: white;
    }
  }
}

.cta-card {
  background: white;
  border: 0;
  border-radius: 10px;
  box-shadow: 0 0 50px rgba(black, .15);
  margin-bottom: 15px !important;

  img {
    margin: 0 auto;
  }

  a {
    color: $primary-color;

    &:hover,
    &:focus {
      color: black;
    }
  }
}

.cta-image {
  a {
    color: white;

    &:hover,
    &:focus {
      color: $primary-color;
    }
  }
}

.highlighted-text {
  border-radius: 5px;

  &:before {
    background: $primary-color;
    border-radius: 5px;
  }
}

.card-style {
  padding: 20px 35px 35px;
  background: white;
  border-radius: 5px;
  box-shadow: 0 0 50px rgba(black, .15);

  h2,
  h3 {
    margin-bottom: 0;
  }

  p {
    margin: 15px 0;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  figure {
    position: relative;
    margin: 0 auto;

    img {
      margin: 0 auto;
    }
  }

  &[data-arx-type] {
    figure {
      left: auto;
      width: 100%;
    }

    img {
      margin: 0 auto;
      width: 100%;
    }
  }
}
