.highlighted-text {
  position: relative;
  margin: 0 auto 25px;
  border-radius: 10px;
  background-color: #f2f2f2;
  padding: 25px 35px 25px 55px;
  @include medium-up {
    padding: 35px 60px 35px 105px;
  }

  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 30px;
    height: 100%;
    background: black;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    top: 0;
    left: 0;
    border-right: 5px solid white;
    @include medium-up {
      width: 45px;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @include fontsize(24 30);
    color: black;
  }

  p,
  ul,
  ol {
    @include fontsize(16 28);
    color: black;
    @include medium-up {
      @include fontsize(18 30);
    }

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}