main {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-style: $regular;
    color: $heading-text-dark;
  }

  h1 {
    color: $primary-color;
    font-size: 46px;
    font-weight: $bold;
  }

  h2 {
    font-weight: 700;
    font-size: 28px;
    font-weight: $bold;
  }

  h3 {
    font-weight: $bold;
    font-size: 22px;
    font-family: $secondary-font;
  }

  h4 {
    font-size: 22px;
    font-weight: $bold;
  }

  h5 {
    font-size: 18px;
    font-weight: $regular;
  }

  h6 {
    font-size: 18px;
    font-weight: $regular;
    font-style: italic;
    margin: 16px 0;
  }

  p,
  ul,
  ol {
    margin: 0 0 20px;
    line-height: 30px;
    color: $paragraph-text;
    font-size: 18px;
  }

  a {
    color: $link-color;
    text-decoration: none;

    &:hover,
    &:focus {
      color: $primary-color;
      text-decoration: underline;
    }
  }

  ul {
    list-style: none;
    padding-left: 20px;
    font-size: 20px;

    li:not([class]) {
      &:before {
        content: '\f111';
        font-family: 'Font Awesome 5 Free';
        font-weight: $bold;
        display: inline-block;
        position: relative;
        font-size: 5px;
        top: -3px;
        left: -5px;
        color: $tan;
      }

      ul {
        margin-bottom: 0;
        font-size: 18px;
      }
    }
  }

  ol {
    list-style: none;
    counter-reset: numbers;
    padding-left: 20px;
    font-size: 18px;

    li {
      counter-increment: numbers;
      position: relative;

      &:before {
        content: counter(numbers)'.';
        font-weight: $bold;
        display: inline-block;
        position: relative;
        left: -5px;
        font-size: 16px;
        color: $primary-color;
      }

      ol {
        margin-bottom: 0;
        font-size: 16px;

        li {
          &:before {
            content: '\f111';
            font-family: 'Font Awesome 5 Free';
            font-weight: $bold;
            display: inline-block;
            position: relative;
            font-size: 5px;
            top: -3px;
            left: -5px;
            color: $tan;
          }
        }
      }
    }
  }

  blockquote {
    position: relative;
    font-style: italic;
    font-size: 22px;
    margin-right: 0;
    margin-left: 0;
    padding: 20px 25px;
    color: $primary-color;
    border: 3px solid $figure-caption-border;

    &:before,
    &:after {
      content: '';
      background: $figure-caption-border-spacers;
      width: 3px;
      height: 67%;
      position: absolute;
    }

    &:before {
      left: -3px;
      bottom: -3px;
    }

    &:after {
      right: -3px;
      top: -3px;
    }

    p {
      line-height: 45px;
      color: $primary-color;
      font-weight: $light;
      margin: 0;
    }
    @include medium-up {
      font-size: 28px;
      line-height: 45px;
      padding: 40px 50px;
      float: right;
      margin: 0 0 20px 50px;
      max-width: 40%;
      clear: both;

      p {
        font-size: 28px;
      }
    }
  }

  figure {
    figcaption {
      position: relative;
      background: transparent;
      color: $figure-caption-color;
      padding: 30px;
      font-size: 14px;
      font-weight: $regular;
      text-align: center;
      margin-top: 15px;
      border: 3px solid $figure-caption-border;

      &:before,
      &:after {
        content: '';
        background: $figure-caption-border-spacers;
        width: 3px;
        height: 50%;
        position: absolute;
      }

      &:before {
        left: -3px;
        bottom: -3px;
      }

      &:after {
        right: -3px;
        top: -3px;
      }
    }
  }
}

a.btn {
  display: inline-block;
  padding: 15px;
  background: $button-color;
  border-radius: 50px;
  color: #262626;
  text-decoration: none;
  letter-spacing: 1px;
  transition: .5s;
  text-transform: uppercase;
  @include medium-up {
    padding: 15px 40px;
  }

  &:hover,
  &:focus {
    border-color: white;
    background: $primary-darker-color;
    text-decoration: none;
    color: white;
  }

  &:visited {
    color: white;
  }

  &.btn-secondary {
    background: $primary-color;
    color: white;

    &:hover,
    &:focus {
      background: $button-color;
      color: #262626;
    }
  }
}